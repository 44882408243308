/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
// import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, Link } from 'gatsby';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib components
import { Container, Row, Col, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import InputIcon from '../helpers/input_with_Icon';
import PCNsection from '../shared/constants/PCN';

// Assets
import SEARCH_ICON from '../images/Actualities-page/icons/search.svg';

// Style
import '../page-styles/contacts.scss';

/* -------------------------------------------------------------------------- */
/*                              All Contacts Page                             */
/* -------------------------------------------------------------------------- */

function AllContactsPage({ location }) {
  /* ********************************** HOOKS ******************************** */
  // Localization
  // const { t } = useTranslation();

  // Search
  const [searchInput, setSearchInput] = useState('');

  /* --------------------------------- CONST -------------------------------- */
  const getSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Contacts" />
      <Container className="page-contacts">
        <Visible lg xl xxl>
          <Container className="top flex items-center fluid">
            <Link className="breadcrumb__link" to="/">
              Accueil
            </Link>
            <span className="m-r-l">/</span>
            <Link
              className="breadcrumb__link"
              to={`/${location.pathname.split('/')[1]}`}
            >
              {location.pathname.split('/')[1]}
            </Link>
          </Container>
        </Visible>
        <Row className="search-container top fluid">
          <Col className="fluid" xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}>
            <InputIcon
              type="text"
              image={SEARCH_ICON}
              alt="search_icon"
              placeholder="Rechercher ici"
              method={getSearchInput}
            />
          </Col>
        </Row>
        <PCNsection searchInput={searchInput} />
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

AllContactsPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AllContactsPage;
