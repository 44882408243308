/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
// import { useHistory } from 'gatsby';

// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container, Col } from 'react-grid-system';

// Assets
import PCN_ICON from '../../images/PCN/male.svg';

// APIs
import API from '../APIs/index';

// Style
import '../../page-components/PCN/index.scss';

/* -------------------------------------------------------------------------- */
/*                                  PCN Section                                 */
/* -------------------------------------------------------------------------- */

function PCNsection({ searchInput }) {
  /* ********************************** HOOKS ******************************** */
  const [pcnData, setPcnData] = useState();

  /* -------------------------------- CONSTS --------------------------------- */
  const IS_BROWSER = typeof window !== 'undefined';

  /* ------------------------------ HELPERS --------------------------------- */
  useEffect(() => {
    axios
      .get(API('pcns'))
      .then(({ data: { data } }) => setPcnData(data))
      .catch((error) => error);
  }, []);

  /* -------------------------------- HELPERS ------------------------------- */
  const onClickContact = (pcnMember) => {
    if (IS_BROWSER) {
      localStorage.setItem('contacted_person', JSON.stringify(pcnMember));
    }
  };

  /* ------------------------------ CONST ----------------------------------- */
  const data = pcnData
    ?.filter((filtredPCN) =>
      filtredPCN.attributes.first_name
        .toLowerCase()
        .includes(searchInput.toLowerCase()),
    )
    .map((pcn) => {
      /* ---------------------------- CONST ----------------------------------- */
      // const historyPath = useHistory();
      // function HandleRoute() {
      //   historyPath.push('/form-contact-PCN/');
      // }

      return (
        <Col
          key={pcn.id}
          className="PCN text-center fluid"
          xxl={2}
          xl={2}
          lg={2}
          md={4}
          sm={6}
          xs={12}
          fluid
        >
          <Container className="single-PCN">
            <img src={PCN_ICON} alt={pcn?.attributes?.last_name} />
            <h5>{`${pcn?.attributes?.last_name} ${pcn?.attributes?.first_name}`}</h5>
            {/* <p className="top">{pcn.function}</p> */}
            <Link to="/PCN" onClick={() => onClickContact(pcn)}>
              <button className="mt-2" type="submit">
                Contact
              </button>
            </Link>
            {/* <button type="submit" onClick={HandleRoute}>
              Contact
            </button> */}
          </Container>
        </Col>
      );
    });
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="fluid" fluid>
      <div className="pcn-wrapper flex flex-wrap justify-between fluid" fluid>
        {data}
      </div>
    </Container>
  );
}

PCNsection.propTypes = {
  searchInput: PropTypes.string.isRequired,
};

export default PCNsection;
